import Placeholder from './placeholder/';

class PlaceHolderImage extends HTMLElement {
  #connected = false;
  constructor() {
    super();
  }

  async connectedCallback() {
    if (this.#connected) return;
    this.#connected = true;
    new Placeholder(this, 'img');
  }
}

class PlaceHolderSource extends HTMLElement {
  #connected = false;
  constructor() {
    super();
  }

  async connectedCallback() {
    if (this.#connected) return;
    this.#connected = true;
    new Placeholder(this, 'source');
  }
}

customElements.define('placeholder-image', PlaceHolderImage);
customElements.define('placeholder-source', PlaceHolderSource);
